import React from 'react';
import classNames from 'classnames';
import './styles.scss';
interface PageTemplateProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  header: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
}
const PageTemplate = ({
  className,
  header,
  footer,
  children,
  ...props
}: PageTemplateProps) => {
  const pageClasses = classNames('site-main', className);
  return <>
      {header}
      <main className={pageClasses} {...props}>
        {children}
      </main>
      {footer}
    </>;
};
export default PageTemplate;