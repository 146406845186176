import React from 'react';
import classNames from 'classnames';
import Heading from '@afs/components/Heading';
import styles from './styles.module.scss';
export interface NotFoundAnnouncementProps {
  className?: string;
  icon: React.FC<{
    className: string;
  }>;
  heading: string;
  subHeading: string | TrustedHTML;
  button: React.ReactNode;
  children?: React.ReactNode;
}
const NotFoundAnnouncement = ({
  className,
  icon: Illustration,
  heading,
  subHeading,
  button,
  children,
  ...props
}: NotFoundAnnouncementProps) => {
  const componentClasses = classNames(styles.wrapper, className);
  return <div className={componentClasses} {...props} data-sentry-component="NotFoundAnnouncement" data-sentry-source-file="index.tsx">
      <Illustration className={styles.illustration} data-sentry-element="Illustration" data-sentry-source-file="index.tsx" />
      <Heading level={1} className={styles.heading} data-testid="not-found-heading" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
        {heading}
      </Heading>
      <p className={styles.subHeading} dangerouslySetInnerHTML={{
      __html: subHeading
    }} />
      {button}
      {children}
    </div>;
};
export default NotFoundAnnouncement;