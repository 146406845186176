import React from 'react'
import Head from 'next/head'

import PageTemplate from '../../templates/PageTemplate'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Button from '../../atoms/Button'
import NotFoundAnnouncement from '../../molecules/NotFoundAnnouncement'

import NotFound from '../../../svgs/illustrations/not-found.svg'

import notFoundPage from './notFoundPage.module.scss'

const NotFoundPage = () => {
  return (
    <PageTemplate
      className={notFoundPage.main}
      header={<Header showNavigation />}
      footer={<Footer variant="gray" />}
    >
      <Head>
        <title>Page Not Found | Accommodation for Students</title>
        <meta name="robots" content="noindex, follow" />
      </Head>
      <div className={notFoundPage.layout}>
        <NotFoundAnnouncement
          icon={NotFound}
          heading="Page not found"
          subHeading="We can't seem to find the page you're looking for."
          button={
            <Button to="/" primary data-testid="overview-button">
              Go to homepage
            </Button>
          }
          data-testid="not-found"
        />
      </div>
    </PageTemplate>
  )
}

export default NotFoundPage
